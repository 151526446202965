import axios from 'axios';

import DefaultHighImage from './assets/utils/images/avatars/default_high.png';

import {server_host_api} from 'Utils';

import {setDefaultLanguage} from 'react-switch-lang';

export const TEMPORARY_TOKEN_KEY = "@intexfy_temporary_token";
export const TOKEN_KEY = "@intexfy_token";
export const USERNAME = "@intexfy_username";
export const USERID = "@intexfy_userid";
export const CUSTOMERID = "@intexfy_customerid";
export const USERTYPE = "@intexfy_usertype";
export const USERDESCRIPTION = "@intexfy_userdescription";
export const USERAVATAR = "@intexfy_useravatar";
export const COMPANYAVATAR = "@intexfy_companyavatar";
export const LANGUAGE = "@intexfy_language";
export const MAIL = "@intexfy_mail";
export const ALLOWFILTER = "@intexfy_allowfilter";
export const PASSWORD = "@intexfy_password";
const CUSTOMER_FEATURES = "@intexfy_customer_features";
const TEAM_FEATURES = "@intexfy_team_features";
const COMPANYNAME = "@intexfy_company_name";
const COMPANYCNPJ = "@intexfy_cnpj";
const COMPANYSOCIAL = "@intexfy_social_name";
const USER_CREATED_AT = "@intexfy_user_created_at";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setTemporaryToken = (token) => localStorage.setItem(TEMPORARY_TOKEN_KEY, token);
export const getTemporaryToken = () => localStorage.getItem(TEMPORARY_TOKEN_KEY);
export const getAuth = () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`;
export const getTemporaryAuth = () => `Bearer ${localStorage.getItem(TEMPORARY_TOKEN_KEY)}`;
export const setUserId = (id) => localStorage.setItem(USERID, id);
export const getUserId = () => localStorage.getItem(USERID);
export const setMail = (mail) => localStorage.setItem(MAIL, mail);
export const getMail = () => localStorage.getItem(MAIL);
export const setAllowFilter = (allowFilter) => localStorage.setItem(ALLOWFILTER, allowFilter);
export const getAllowFilter = () => localStorage.getItem(ALLOWFILTER);
export const setPassword = (password) => localStorage.setItem(PASSWORD, password);
export const getPassword = () => localStorage.getItem(PASSWORD);
export const getCustomerFeat = () => localStorage.getItem(CUSTOMER_FEATURES);
export const getCustomerId = () => localStorage.getItem(CUSTOMERID);
export const getUserType = () => localStorage.getItem(USERTYPE);
export const getUserName = () => localStorage.getItem(USERNAME);
export const getUserDescription = () => localStorage.getItem(USERDESCRIPTION);
export const getUserAvatar = () => localStorage.getItem(USERAVATAR);
export const setUserAvatar = (url) => localStorage.setItem(USERAVATAR, url);
export const setUserName = (name) => localStorage.setItem(USERNAME, name);
export const setUserDescription = (description) => localStorage.setItem(USERDESCRIPTION, description);
export const getCompanyAvatar = () => localStorage.getItem(COMPANYAVATAR);
export const setCompanyAvatar = (url) => localStorage.setItem(COMPANYAVATAR, url);
export const getCompanyName = () => localStorage.getItem(COMPANYNAME);
export const getCompanySocial = () => localStorage.getItem(COMPANYSOCIAL);
export const getCompanyCnpj = () => localStorage.getItem(COMPANYCNPJ);
export const getLanguage = () => localStorage.getItem(LANGUAGE);
export const getLanguageHeader = () => localStorage.getItem(LANGUAGE) === "pt" ? "pt-br" : localStorage.getItem(LANGUAGE);
export const getUserCreatedAt = () => localStorage.getItem(USER_CREATED_AT);

export default axios.interceptors.response.use(response => {
return response;
}, error => {
  if (error.response.status === 401) {
    if (error.response.request.responseURL.indexOf("token") === -1) {
      localStorage.removeItem("@intexfy_token");

      window.location.reload(false);
    }
  }
  return error;
});

export const login = async (auth) => {
  localStorage.setItem(TOKEN_KEY, auth.data.token);

  var allDone = false;

  localStorage.setItem(COMPANYAVATAR, DefaultHighImage);

  await axios.get(`${server_host_api}/user/${auth.data.user_id}/`,{
    headers: { Authorization: getAuth() }
  })
  .then(res => {
    if (res.status === 200) {
      const { data } = res.data;
      localStorage.setItem(USERID, data.id);
      localStorage.setItem(USERNAME, data.email);
      localStorage.setItem(USERDESCRIPTION, `${data.first_name === null ? "" : data.first_name } ${data.last_name === null? "" : data.last_name }`);
      localStorage.setItem(CUSTOMERID, auth.data.customer_id);
      localStorage.setItem(CUSTOMER_FEATURES, JSON.stringify(data.customer_features));
      localStorage.setItem(TEAM_FEATURES, JSON.stringify(data.team_features));
      localStorage.setItem(USER_CREATED_AT, JSON.stringify(data.created_at));
      localStorage.setItem(USERTYPE, data.type);
      localStorage.setItem('selectedClientsConfig', "");

      if (res.data.data.image) {
      localStorage.setItem(USERAVATAR, data.image);
      } else {
      localStorage.setItem(USERAVATAR, DefaultHighImage);
      }


      allDone = true;
    }
  })
  .catch(error => {
    allDone = false;
  });

  if (allDone) {
    allDone = false;

    await axios.get(`${server_host_api}/customer/${getCustomerId()}/`,{
      headers: { Authorization: getAuth() }
    })
    .then(res => {
        if (res.status === 200) {
          localStorage.setItem(COMPANYSOCIAL, res.data.data.social_name);
          localStorage.setItem(COMPANYNAME, res.data.data.company_name); 
          localStorage.setItem(COMPANYCNPJ, res.data.data.registration_code)         
          if (res.data.data.image) {
            localStorage.setItem(COMPANYAVATAR, res.data.data.image);
          }
         
          allDone = true;
        } else {
          allDone = false;
        }
    })
    .catch(error => {
      allDone = false;
    });
  }

  return allDone;
};

export const saveLanguage = (language) => {
  localStorage.setItem(LANGUAGE, language);
}

export const logout = () => {

  axios.post(`${server_host_api}/lists/logout/`,"",{
    headers: { Authorization: getAuth() }
  })
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USERNAME);
      localStorage.removeItem(USERID);
      localStorage.removeItem(CUSTOMERID);
      localStorage.removeItem(USERDESCRIPTION);
      localStorage.removeItem(USERAVATAR);
      localStorage.removeItem(COMPANYAVATAR);
      localStorage.removeItem(LANGUAGE);
      localStorage.removeItem(TEMPORARY_TOKEN_KEY);
      localStorage.removeItem(MAIL);
      localStorage.removeItem(PASSWORD);
      localStorage.removeItem(CUSTOMER_FEATURES);
      localStorage.removeItem(TEAM_FEATURES);
      localStorage.removeItem('selectedClientsConfig');

      window.location.reload(false);

      var userLang = navigator.language || navigator.userLanguage; 

      if (userLang) {
          userLang = userLang.substring(0,2);
      }

      switch (userLang) {
          case "pt":
              setDefaultLanguage('pt');
              break;
          case "es":
              setDefaultLanguage('es');
              break;
          case "en":
              setDefaultLanguage('en');
              break;
          default:
              setDefaultLanguage('en');
              break;
}
  
};

function getCustomerAndTeamFeatures() {
  const customerFeatures = JSON.parse(localStorage.getItem(CUSTOMER_FEATURES));
  const teamFeatures = JSON.parse(localStorage.getItem(TEAM_FEATURES));
  const allFeatures = [...customerFeatures, ...teamFeatures];
  // Remove objetos duplicados
  return [...new Set(allFeatures.map(s => JSON.stringify(s)))].map(s => JSON.parse(s));
}

export const isShowMenuIcp = () => !!getCustomerAndTeamFeatures()
      .filter(item => item.code === 'MNGICP')
      .length;

export const isShowMenuAccounts = () => !!getCustomerAndTeamFeatures()
  .filter(item => 
      item.code === 'VWD'
      || item.code === 'DLR' 
      || item.code === 'DLT'
      || item.code === 'VWL'
      || item.code === 'CLL'
      || item.code === 'VWDE'
      || item.code === 'VMWL'
      || item.code === 'CMLL')
  .length;

export const isShowSubmenuDashboard = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'DLR' || item.code === 'VWDE')
  .length;

export const isShowSubmenuAccountList = () => !!getCustomerAndTeamFeatures()
  .filter(item => 
      item.code === 'DLR'
      || item.code === 'DLT' 
      || item.code === 'VWL'
      || item.code === 'CLL'
      || item.code === 'VMWL'
      || item.code === 'CMLL')
  .length;

export const isShowMenuTools = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'ANLM' || item.code === 'SRCHFY')
  .length;

export const isShowSubmenuSearchFy = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'SRCHFY')
  .length;

export const isShowSubmenuMarketAnalysis = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'ANLM')
  .length;

export const isShowMenuSettings = () => !!getCustomerAndTeamFeatures()
  .filter(item => 
      item.code === 'MNGU'
      || item.code === 'MNGT' 
      || item.code === 'MNGJ'
      || item.code === 'MINT')
  .length;

export const isShowSubmenuUsers = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'MNGU')
  .length;

  export const isShowSubmenuIntegrations = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'MINT')
  .length;

export const isShowSubmenuJustifications = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'MNGJ')
  .length;

export const isShowSubmenuTags = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'MNGT')
  .length;

export const isShowSubmenuFinancial = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'MGFIN')
  .length;

export const isShowButtonDistributeAccounts = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'DLT' || item.code === 'DLR')
  .length;

export const isShowButtonTeams = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'DLT')
  .length;

export const isShowButtonUsers = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'DLR')
  .length;

export const isShowButtonClients = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'MNGCUS')
  .length;

export const isShowButtonSmilesAndJustifications = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'CLL' || item.code === 'CMLL')
  .length;

export const isShowTabAccountAnalysisAndAccountsByRelevance = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'VWD')
  .length;

export const isShowTabTeamAnalyze = () => !!getCustomerAndTeamFeatures()
  .filter(item => item.code === 'VWDE')
  .length;